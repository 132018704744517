import { Link } from 'react-router-dom'; // Import Link from React Router
import './footer.style.css';
import logo from '../../GadgetRanker_400px.png'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="sec_container">
        <div className="footer_logo">
            <a href="/"><img src={logo} alt="GadgetRanker" /></a>
        </div>
        <div className="footer_link">
          <ul className="link_items">
            <li className="link_item"><Link to="https://www.trayelevendime.com/o-pfwr-o45-c8017a6b57bbf4f3aa615cf4ec970d58">Unsubscribe</Link></li>
            <li className="link_item"><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li className="link_item"><Link to="/terms-of-use">Terms Of Use</Link></li>
            <li className="link_item"><Link to="/do-not-sell">Do Not Sell</Link></li>
            <li className="link_item"><Link to="/disclaimer">Disclaimer</Link></li>
          </ul>
        </div>
        <small className="trademark_text">
          THIS IS AN ADVERTISEMENT AND MAY NOT BE CONSIDERED AN ACTUAL NEWS ARTICLE, CONSUMER AWARENESS UPDATE, OR EDITORIAL CONTENT.
          <br></br><br></br>FINESSEFIXERS.COM SERVES AS AN INDEPENDENT ENTITY FOR ADVERTISING DISSEMINATION AND SHALL NOT BE CONSIDERED A PROFESSIONAL ADVISOR SUCH AS A MORTGAGE BROKER, INSURANCE BROKER, FINANCIAL ADVISOR OR LEGAL ADVISOR. THIS SITE PROVIDES INFORMATIONAL CONTENT AND IS NOT A SUBSTITUTE FOR PROFESSIONAL FINANCIAL ADVICE. WE RECOMMEND CONSULTING A QUALIFIED FINANCIAL ADVISOR FOR GUIDANCE SPECIFIC TO YOUR NEEDS. THE AUTHOR IS NOT RESPONSIBLE FOR ANY MISUNDERSTANDINGS OR MISUSE OF THE INFORMATION PRESENTED HERE.
          <br></br><br></br>The content you're reading is designed in the style of an advertorial, courtesy of finessefixers.com. Advertorials blend the look and feel of an editorial with the persuasive intent of an advertisement. It’s important to note that finessefixers.com MAY RECEIVE MONETARY COMPENSATION FOR CLICKS, PURCHASES, OR THE REFERRAL OF LEADS MADE THROUGH THE MATERIAL SHARED ON THIS PAGE. finessefixers.com is an independent promotional platform offering comparative insights on various products and services. The images of individuals used are representational models for illustrative purposes. The assessments and ratings we provide are based on a well-rounded analysis of brand integrity, product range, customer interaction, and performance, as well as financial compensation received, all of which shape our rankings and reviews.
          <br></br><br></br>Price details and offers may vary by region and income, with advertisers having their own qualifications. finessefixers.com does not use your data for financial transactions, but shared personal information will be passed to advertisers. Offers may involve subscriptions with recurring fees until you cancel. Under this agreement, you'll incur regular charges until you opt to cancel. For full terms and information on extra fees including shipping and handling, visit the advertiser’s site and review their terms and conditions. Trademarks featured on our website are the property of their respective owners, with no endorsement of our website or services implied or expressed. As an independently owned platform, we offer insights and feedback on products and services based solely on our in-depth research and the expertise of our editorial team. The opinions here are entirely our own. While we strive for accuracy, all information available on this site is provided as is and subject to change without notice, as detailed in our Terms of Use. We disclaim all warranties related to the information presented.
        </small>
        <small className="copyright">
          ©2023 finessefixers.com. All Rights Reserved.
        </small>
      </div>
    </footer>
  );
}
