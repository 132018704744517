import React from 'react';
import './DoNotSell.css';

const DoNotSell = () => {
  return (
    <div className="do-not-sell-container">
      <h1>FinesseFixers.com - Do Not Sell My Personal Information</h1>
      <p>
        The following notice and the options thereof are intended only for residents of California, Colorado, Connecticut, Utah, and Virginia.
      </p>
      <p>
        As a California resident, under the CCPA, you have the right to opt out of the "sale" of your "personal information." Residents may request their personal information not be disclosed to third parties from Colorado, Connecticut, Utah, and Virginia. At FinesseFixers.com, we respect and adhere to these rights. For more information about how we collect, use, and share personal information, please review our Privacy Policy.
      </p>

      <h2>Use of Your Information</h2>
      <p>
        Our advertising partners, technology companies, advertising services-related companies, and we do not knowingly "sell" your personal information, though some use your personal information for creating or delivering interest-based advertising and other communications, which may be considered a "sale" under the CCPA. When using our services outlined in our Privacy Policy, information may be shared from or about you, including personal information. We also share limited personal information with business partners for marketing purposes.
      </p>

      <h2>Opting Out of Data Sharing</h2>
      <p>To opt out of the sharing of your personal information for interest-based marketing:</p>
      <ol>
        <li>Email us at <a href="mailto:info@FinesseFixers.com">info@FinesseFixers.com</a> with your email address, name, and state of residence.</li>
        <li>Follow the unsubscribe link in any email, text, or notification.</li>
        <li>Opt-out of browser-based cookies by updating your browser settings.</li>
        <li>Visit the following link: <a href="https://www.trayelevendime.com/o-pfwr-o45-c8017a6b57bbf4f3aa615cf4ec970d58">[Link]</a></li>
      </ol>

      <h2>Other Uses of Your Information</h2>
      <p>Opting out does not stop non-personalized advertising. We may still use your information for analytics, measurement, and other purposes as outlined in our Privacy Policy.</p>

      <h2>Still Personalized Advertising</h2>
      <p>Despite opting out, you might still receive generic advertising not based on your personal information.</p>

      <h2>Learn More</h2>
      <p>There is also more information from these non-affiliated third-party resources on your internet-based advertising choices regarding:</p>
      <ul>
        <li>Opting-out of Advertising (National Advertising Initiative)</li>
        <li>Website and App Advertising (Digital Advertising Alliance)</li>
        <li>Cookies and Privacy for EU (European Interactive Digital Advertising Alliance)</li>
      </ul>

      <h2>Contact Us</h2>
      <p>For further questions or requests regarding your privacy rights, please email us at <a href="mailto:info@FinesseFixers.com">info@FinesseFixers.com</a>.</p>

      <h2>Privacy Policy</h2>
      <p>For complete details on the categories of personal information we "sell" and our privacy practices, please review our Privacy Policy.</p>
    </div>
  );
};

export default DoNotSell;
